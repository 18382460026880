.section {
  max-width: 548px;
  margin: 12px 16px; }

.title {
  padding: 24px 24px 0 24px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  font-family: 'Roboto', sans-serif; }

.summary {
  background-color: #f7f7f7;
  font-size: 12px;
  font-weight: 300;
  font-family: 'Lato', sans-serif;
  padding: 24px; }

.summary .lineitems {
  border-bottom: 1px solid #a49e94;
  margin-bottom: 16px; }

.summary .lineitem {
  margin-bottom: 16px; }

.summary .finalline {
  margin-bottom: 8px; }

.summaryLabel {
  text-transform: lowercase;
  font-weight: 500; }

.summaryAmount {
  float: right; }

.finalline .summaryAmount {
  font-size: 14px;
  font-weight: 500; }

.discounted {
  color: #ec4067; }

.total {
  text-transform: lowercase;
  font-weight: bold; }

@media (min-width: 560px) {
  .section {
    margin: 12px auto; } }

@media (min-width: 750px) {
  .section {
    max-width: 748px;
    display: flex;
    flex-direction: row; }
  .summary {
    width: 264px;
    padding-top: 30px; } }
