.error {
  max-width: 512px;
  margin: auto;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  background-color: #fce588;
  text-align: left; }

.error h3 {
  font-size: 16px;
  margin-bottom: 4px;
  line-height: 1.2; }

.error h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 64px;
  line-height: 1.2;
  font-weight: 900; }

.error h2 {
  font-size: 18px;
  margin: 24px 0 0;
  line-height: 1.2;
  color: #423d33; }

.page {
  display: flex;
  flex-flow: column;
  min-height: 100vh; }
