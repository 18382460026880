.signup {
  grid-area: signup; }

.image {
  grid-area: image; }

.value {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 12px;
  grid-template-rows: auto;
  grid-template-areas: '. signup signup signup signup signup signup signup signup signup signup .' 'image image image image image image image image image image image image'; }

@media (min-width: 1200px) {
  .value {
    grid-template-areas: '. signup signup signup signup signup signup . . . . .'; }
  .image {
    grid-row: 1;
    grid-column-start: 7;
    grid-column-end: span 5; }
  .container {
    overflow-x: hidden; } }
