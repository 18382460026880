.box {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.label {
  font-family: 'Roboto', sans-serif;
  text-transform: lowercase;
  font-size: 24px;
  color: #26231c;
  margin-bottom: 32px; }
