.container {
  overflow-x: hidden;
  height: 100vh;
  overflow-y: hidden; }

.form {
  grid-area: form; }

.value {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 5%;
  grid-template-rows: auto;
  grid-template-areas: '. form form form form form form form form . . .'; }

.image {
  margin-top: 12px;
  grid-area: image;
  grid-row: 1;
  grid-column-start: 6;
  grid-column-end: span 5;
  z-index: -1; }

@media (min-width: 640px) {
  .value {
    grid-template-areas: '. form form form form form form form . . . .';
    margin-top: none; }
  .image {
    margin-top: none;
    grid-row: 1;
    grid-column-start: 7;
    grid-column-end: span 5; } }
