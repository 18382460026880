.invite {
  display: flex;
  flex-direction: column;
  max-width: 512px;
  margin: 12px;
  padding: 32px 24px;
  font-family: 'Lato', sans-serif;
  text-align: left;
  background-color: #fce588; }

.invite h3 {
  font-size: 16px;
  margin-bottom: 4px;
  line-height: 1.2; }

.invite h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 42px;
  line-height: 1.2;
  font-weight: 900; }

.invite h2 {
  font-size: 18px;
  margin: 12px 0 0;
  color: #423d33; }

.invite h2 span {
  font-weight: 700; }

.fullname {
  max-width: 256px; }

.nickname {
  max-width: 198px; }

.join {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #f9f8f6;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  text-transform: lowercase;
  letter-spacing: 0.025em;
  background-color: #f7c94a;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 32px; }

.join:hover {
  color: #fff;
  cursor: pointer;
  background-color: #fadb61;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08); }

@media (min-width: 560px) {
  .invite {
    margin: 12px auto; }
  .invite h1 {
    font-size: 64px; } }

@media (min-width: 750px) {
  .invite {
    max-width: 700px; } }
