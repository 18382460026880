.inputWrapper {
  padding-top: 4px;
  margin-bottom: 4px; }

.inputWrapper input {
  outline: 0;
  padding: 0 24px 0 0;
  line-height: 36px;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  font-family: 'Lato', sans-serif; }

.inputWrapper input::placeholder {
  font-family: 'Lato', sans-serif; }

.inputLabel {
  padding-top: 4px;
  width: 100%;
  font-family: 'Avenir LT Std Heavy', sans-serif;
  font-size: 12px;
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  position: relative;
  left: -1px;
  height: 12px;
  text-transform: lowercase;
  transition: opacity 0.2s ease-out, color 0.2s ease-out; }

.inputLabel.val {
  opacity: 1; }

.hidden {
  opacity: 0; }

.show {
  opacity: 1; }

.none {
  display: none; }
