.page {
  display: flex;
  flex-flow: column;
  height: 100%; }

.formsections {
  margin: 36px 0;
  min-height: 100vh; }

.page section {
  max-width: 500px;
  margin: 12px 16px;
  padding: 24px 24px; }

.page section .title {
  margin-bottom: 4px; }

.page section .title h2 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  font-family: 'Roboto', sans-serif; }

.page section .title p {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 1.2; }

.form {
  width: 100%; }

.names {
  display: flex;
  flex-direction: column; }

.fullname {
  max-width: 148px;
  margin-right: 16px; }

.nickname {
  max-width: 96px; }

.email {
  max-width: 256px; }

.phone {
  max-width: 256px; }

.group {
  max-width: 256px; }

@media (min-width: 560px) {
  .page section {
    margin: 24px auto; } }

@media (min-width: 750px) {
  .page section {
    min-width: 750px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0; }
  .page section .title {
    width: 325px;
    padding: 24px; }
  .page section .content {
    width: 300px;
    padding: 24px; }
  .page section .title.lowspace {
    margin-right: 76px; }
  .content {
    margin-top: -8px; } }
