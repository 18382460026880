/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700|Roboto:500,700");
:global(html),
:global(body),
:global(div),
:global(span),
:global(applet),
:global(object),
:global(iframe),
:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(h6),
:global(p),
:global(blockquote),
:global(pre),
:global(a),
:global(abbr),
:global(acronym),
:global(address),
:global(big),
:global(cite),
:global(code),
:global(del),
:global(dfn),
:global(img),
:global(ins),
:global(kbd),
:global(q),
:global(samp),
:global(small),
:global(strike),
:global(sub),
:global(sup),
:global(tt),
:global(var),
:global(b),
:global(u),
:global(i),
:global(center),
:global(dl),
:global(dt),
:global(dd),
:global(ol),
:global(ul),
:global(li),
:global(fieldset),
:global(form),
:global(label),
:global(legend),
:global(table),
:global(caption),
:global(tbody),
:global(tfoot),
:global(thead),
:global(tr),
:global(th),
:global(td),
:global(article),
:global(aside),
:global(canvas),
:global(details),
:global(embed),
:global(figure),
:global(figcaption),
:global(footer),
:global(header),
:global(hgroup),
:global(menu),
:global(nav),
:global(output),
:global(ruby),
:global(section),
:global(summary),
:global(time),
:global(mark),
:global(audio),
:global(video) {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
:global(article),
:global(aside),
:global(details),
:global(figcaption),
:global(figure),
:global(footer),
:global(header),
:global(hgroup),
:global(menu),
:global(nav),
:global(section) {
  display: block; }

:global(body) {
  line-height: 1; }

:global(ol),
:global(ul) {
  list-style: none; }

:global(blockquote),
:global(q) {
  quotes: none; }

:global(blockquote:before),
:global(blockquote:after),
:global(q:before),
:global(q:after) {
  content: '';
  content: none; }

:global(table) {
  border-collapse: collapse;
  border-spacing: 0; }

/* React Modal handling once open */
:global(.ReactModal__Body--open) {
  overflow-y: hidden; }

/* make body defaut 100% height */
:global(html),
:global(body),
:global(#app) {
  min-height: 100vh; }

/* Stripe Elements styling */
:global(.StripeElement) {
  display: block;
  margin: 10px 0 10px 0;
  max-width: 500px;
  padding: 16px 14px;
  font-size: 1em;
  font-family: 'Lato', sans-serif;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white; }

:global(.StripeElement--focus) {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease; }

/* Google font loading */
