.visualPickerFooter h1 {
  margin-bottom: 4px; }

.visualPickerFooter h2 {
  line-height: 1.2; }

.visualPickerOption input:checked ~ label > [data-id='visual-picker_option'] {
  border-color: #f7c94a; }

.visualPickerOption
input:checked
~ label
> [data-id='visual-picker_option']
> span {
  border-right-color: #f7c94a;
  border-top-color: #f7c94a; }

.visualPickerOption input:hover ~ label > [data-id='visual-picker_option'] {
  border-color: #f7c94a; }
