.how {
  display: grid;
  grid-template-rows: 72px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 72px;
  grid-template-columns: 10% 1fr 10%;
  grid-template-areas: '. . .' '. headers .' '. list .' '. list .' '. list .' '. list .' '. list .' '. list .' '. . .';
  min-height: 700px;
  background: #ffffff; }

.headers {
  grid-area: headers;
  text-align: left;
  max-width: 80%;
  font-size: 20px; }

.headers h1 {
  font-family: 'Roboto', sans-serif;
  color: #f0003f;
  font-weight: 700;
  margin-bottom: 4px; }

.headers h2 {
  font-family: 'Lato', sans-serif;
  color: #615c51; }

.how ul {
  grid-area: list;
  max-width: 80%;
  list-style: none; }

.how li {
  text-align: left;
  margin-bottom: 32px; }

.how h3 {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin-bottom: 2px;
  color: #26231c; }

.how p {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #615c51; }

.startLink a {
  color: #f0003f;
  font-family: 'Lato', sans-serif;
  font-size: 14px; }

.startLink a:link {
  text-decoration: none; }

.startLink a:visited {
  text-decoration: none; }

@media (min-width: 865px) {
  .how {
    display: grid;
    grid-template-rows: 72px 1fr 1fr 1fr 1fr 1fr 1fr 72px;
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas: '. . . . . . . .' '. . headers headers headers . . .' '. . list list list list . .' '. . list list list list . .' '. . list list list list . .' '. . list list list list . .' '. . list list list list . .' '. . . . . . . .';
    min-height: 700px; }
  .headers {
    grid-area: headers;
    max-width: none;
    font-size: 24px; }
  .headers h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 4px; }
  .headers h2 {
    font-family: 'Lato', sans-serif;
    font-size: 24px; }
  .how ul {
    grid-area: list;
    max-width: 80%; }
  .how li {
    margin-bottom: 48px; }
  .how h3 {
    font-size: 20px;
    margin-bottom: 4px; }
  .how p {
    font-size: 16px;
    line-height: 1.5; }
  .startLink a {
    font-size: 16px; } }
