.toastContainer {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  text-transform: lowercase; }

.toastStyles {
  border-radius: 12px;
  background: #fce588; }

.toastBody {
  text-align: center;
  color: #27241d;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 12px; }

.toastNoProgress {
  background: #fce588; }

.toastProgress {
  background: #f0003f; }
