@charset "UTF-8";
.editor {
  padding: 20px 0 26px;
  background: white;
  line-height: 1.58; }

.editor:focus {
  outline: none; }

.preview {
  opacity: 0.7; }

.ul_list {
  margin-top: 20px; }
  .ul_list li::before {
    content: '•';
    width: 1em;
    margin-left: -1em;
    position: absolute;
    display: inline-block;
    box-sizing: border-box;
    color: #f0003f; }

.ol_list {
  margin-top: 20px;
  counter-reset: li; }
  .ol_list li::before {
    content: counter(li) ".";
    counter-increment: li;
    width: 2em;
    margin-left: -2.5em;
    text-align: right;
    position: absolute;
    display: inline-block;
    box-sizing: border-box;
    color: #f0003f; }

.list_item {
  line-height: 1.58;
  margin-left: 22px;
  margin-bottom: 14px; }
