.purchasedisclaimer {
  text-align: center;
  padding-left: 60px;
  padding-right: 60px;
  line-height: 20px;
  font-size: 11px;
  color: #b9b3a7;
  font-weight: 300;
  margin-bottom: 16px; }

.ssl {
  text-align: center;
  font-weight: 100;
  font-size: 12px;
  color: #000;
  opacity: 0.36;
  margin-top: 14px; }

.termsLink {
  font-weight: 500;
  color: #423d33;
  text-decoration: none; }

.checkout {
  padding: 24px; }

.checkout button {
  width: 100%;
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #f9f8f6;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: lowercase;
  letter-spacing: 0.025em;
  background-color: #f7c94a;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px; }

.checkout button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #fadb61;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08); }
