.hprimary {
  font-family: 'Roboto';
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #26231c; }

.hsecondary {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 4px;
  line-height: 1.2;
  color: #423d33; }

.htertiary {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: #615c51;
  margin-bottom: 2px; }

.tb {
  font-family: 'Lato';
  font-size: 14px;
  font-weight: 400;
  line-height: 1.1;
  color: #2680c0;
  margin-bottom: 2px;
  cursor: pointer; }

@media (min-width: 640px) {
  /* spacing */
  .hprimary {
    margin-bottom: 16px; }
  .hsecondary {
    margin-bottom: 8px; }
  .htertiary {
    margin-bottom: 4px; }
  /* font size */
  .hprimary {
    font-size: 48px; }
  .hsecondary {
    font-size: 30px; }
  .htertiary {
    font-size: 20px; } }
