.textspace {
  max-width: 420px;
  margin-bottom: 44px; }

.error {
  margin-top: 12px; }

@media (min-width: 640px) {
  .textspace {
    max-width: 550px;
    margin-bottom: 60px; } }
