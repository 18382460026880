.page {
  background: white; }

.section {
  background: white; }

.input {
  background: 0 0;
  color: #26231c;
  box-shadow: inset 0 -2px 0 0 #d4cfc4;
  border: none;
  outline: 0; }

.input::placeholder {
  color: #a49e94; }

.input[value]:not([value='']) {
  box-shadow: inset 0 -2px 0 0 #f0b428; }

.bottomlabel {
  color: #26231c; }

.page .title {
  color: #26231c; }

.page .text {
  color: #4f4940; }

.section .title {
  color: #4f4940; }

.section .text {
  color: #a49e94; }

.inputLabel {
  color: #dd901d; }

.inputLabel.val {
  color: #f0003f; }

.input.val {
  box-shadow: inset 0 -2px 0 0 #f0003f; }
