.button {
  min-width: 150px;
  background: #f7c948;
  border-radius: 24px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  outline: 0;
  user-select: none;
  display: inline-block;
  height: 42px;
  margin: 1px 0 2px;
  padding: 0 8px;
  text-align: center;
  white-space: nowrap;
  transition: 0.5s;
  text-decoration: none;
  cursor: pointer; }

.button div {
  font-family: 'Lato', sans-serif;
  line-height: 36px;
  position: relative;
  color: #f9f8f6;
  text-transform: lowercase;
  overflow: hidden;
  font-size: 18px; }
