.confirmation {
  padding: 0px 0px 32px;
  font-family: 'Lato', sans-serif;
  text-align: left;
  margin: 48px auto;
  display: flex;
  flex-direction: column;
  max-width: 512px; }

.banner {
  background-color: #fce588;
  padding: 32px 24px; }

.banner h3 {
  font-size: 16px;
  margin-bottom: 4px;
  line-height: 1.2; }

.banner h1 {
  font-size: 64px;
  line-height: 1.2;
  font-weight: 900;
  font-family: 'Roboto', sans-serif; }

.banner h2 {
  font-size: 18px;
  font-weight: 700;
  margin: 12px 0 0; }

.cta {
  padding: 0px 24px;
  color: #423d33;
  line-height: 1.2; }

.cta h3 {
  font-family: 'Roboto', sans-serif;
  color: #27241d;
  font-size: 20px;
  text-align: center;
  padding: 24px 0; }

.cta h4 span {
  color: #f0003f;
  font-weight: 700; }

.cta table {
  margin: 16px auto;
  border-collapse: separate;
  border-spacing: 0px 4px; }

.cta tr {
  cursor: pointer; }

.cta .fieldName {
  color: #a39e93; }

.cta .field {
  padding-left: 10px; }

.cta > table > tbody > tr > td > span.copy {
  opacity: 0; }

.cta > table > tbody > tr:hover > td > span.copy {
  opacity: 1;
  color: #f0003f; }

span.copy {
  padding-left: 10px; }
