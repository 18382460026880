.top {
  background-image: radial-gradient(1200px circle at 30% -10%, #ffffff 50%, #f0003f 50%); }

.vp {
  min-height: 500px;
  margin-top: 16px; }

.vptext {
  margin-bottom: 44px; }

.vptextsecond {
  max-width: 420px; }

.signUp {
  max-width: 460px; }

.placeCell {
  width: 100%;
  padding-right: 24px;
  vertical-align: top;
  min-width: 150px; }

.placeContainer {
  width: 100%;
  height: 36px;
  margin-bottom: 2px;
  border-bottom: 1px solid #26231c; }
  .placeContainer input::placeholder {
    color: #a49e94;
    font-family: 'Lato', sans-serif; }

.place {
  width: 100%;
  background: 0 0;
  border: none;
  outline: 0;
  padding: 0;
  text-transform: lowercase;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  top: 4px;
  font-family: 'Lato', sans-serif;
  line-height: 32px;
  position: relative;
  color: #f0003f; }

.buttonCell {
  vertical-align: top;
  min-width: 120px; }

.button {
  min-width: 150px;
  background: #f7c948;
  border-radius: 24px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  outline: 0;
  user-select: none;
  display: inline-block;
  height: 36px;
  margin: 1px 0 2px;
  padding: 0 8px;
  text-align: center;
  white-space: nowrap;
  transition: 0.5s;
  text-decoration: none;
  cursor: pointer; }

.button div {
  font-family: 'Lato', sans-serif;
  line-height: 32px;
  position: relative;
  color: #f9f8f6;
  text-transform: lowercase;
  overflow: hidden;
  font-size: 16px; }

.heroContainer {
  margin-top: 48px;
  margin-bottom: 32px;
  text-align: center; }

.hero {
  width: 100%;
  max-width: 1024px; }

@media (min-width: 640px) {
  /* section divider background */
  .top {
    background-image: radial-gradient(1500px circle at 30% -10%, #ffffff 50%, #f0003f 50%); }
  /* spacing */
  .vp {
    margin-top: 64px; }
  .vptext {
    margin-bottom: 60px; }
  .vptextsecond {
    max-width: 550px; }
  /* fonts and heights */
  .tb {
    font-size: 16px;
    padding-top: 10px; }
  .placeContainer {
    height: 46px; }
  .place {
    line-height: 42px;
    font-size: 24px; }
  .button {
    min-width: 200px;
    height: 48px; }
  .button div {
    line-height: 42px;
    font-size: 20px; } }

@media (min-width: 1200px) {
  .hero {
    width: 1024px; }
  .heroContainer {
    margin-top: 0; } }
