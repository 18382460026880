.card {
  display: flex;
  flex-direction: column;
  max-width: 512px;
  margin: 12px auto;
  padding: 32px 24px;
  font-family: 'Lato', sans-serif;
  text-align: left; }

.settings {
  display: flex;
  flex-direction: row;
  max-width: 560px;
  margin: 12px auto;
  justify-content: space-between;
  font-family: 'Lato', sans-serif; }

.groupSettings {
  width: 50%;
  margin-right: 12px;
  padding: 32px 24px;
  display: flex;
  flex-direction: column; }

.groupSettings .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 16px; }

.subscriptionSettings {
  width: 50%;
  padding: 32px 24px;
  display: flex;
  flex-direction: column; }

.subscriptionSettings .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 18px; }

.lineitems {
  margin-bottom: 12px; }

.lineitems .item:not(:last-child) {
  margin-bottom: 12px; }

.infolabel {
  text-transform: lowercase;
  color: #576574;
  font-size: 0.875rem; }

.infodetail {
  float: right;
  color: #061c3f; }

.header h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 64px;
  line-height: 1.2;
  font-weight: 900; }

.header h2 {
  font-size: 18px;
  color: #423d33; }

.snips {
  padding: 24px 0px; }

.snips h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  line-height: 1.1;
  font-weight: 900; }

.snips h2 {
  margin-top: 16px;
  line-height: 1.4; }

.on h1 {
  font-size: 48px;
  color: #27241d; }

.on h2 {
  font-size: 16px;
  color: #423d33; }

.off h1 {
  font-size: 32px;
  color: #423d33; }

.off h2 {
  font-size: 14px;
  line-height: 1.2;
  color: #504a40; }

.alert h2 {
  font-weight: 700;
  color: #f0003f; }

.hoverhighlight:hover > h1 {
  cursor: pointer;
  color: #54acdc; }

.avatarSection {
  margin: 6px 0; }

.avatars {
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.sendInvite {
  margin-top: 6px;
  background-color: #f0f4f8;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 32px; }

.sendInvite h2 {
  align-self: center; }

.inputWithButton {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 24px 16px 0px;
  min-width: 224px; }

.modal {
  font-family: 'Lato', sans-serif; }

.modal .content {
  padding: 12px;
  color: #423d33;
  line-height: 1.3; }

.modal .footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.modal .content .creditCard {
  margin: auto;
  border: solid;
  border-radius: 8px;
  padding: 60px 20px 20px;
  width: 200px;
  font-family: 'Lato', sans-serif;
  text-align: left;
  line-height: 1.3;
  text-transform: uppercase;
  color: #576574; }

.creditCard h4 {
  font-size: 16px;
  margin-bottom: 20px; }

.creditCard h5 {
  font-size: 12px; }

.creditCard .expiresLogo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.embedded {
  max-width: 512px;
  margin: 32px auto 12px;
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #d3cec4;
  text-align: left;
  text-transform: lowercase; }

.embedded h2 {
  padding-left: 12px;
  font-size: 16px; }

.restartButton {
  display: flex;
  flex-direction: row;
  justify-content: center; }
