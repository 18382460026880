.info {
  color: #fff; }

.infoButton {
  font-weight: 600; }

.bottomlabel {
  padding-top: 2px;
  font-family: 'Avenir LT Std Heavy', sans-serif;
  font-size: 10px;
  line-height: 14px;
  -webkit-font-smoothing: antialiased;
  position: relative;
  left: -1px;
  text-transform: lowercase;
  transition: opacity 0.2s ease-out, color 0.2s ease-out; }

.plans {
  margin: 32px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }

.plan {
  border-radius: 16px;
  border: none;
  background: #f999b2;
  padding: 16px;
  pointer: cursor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none; }

button > * {
  pointer-events: none; }

.left {
  margin-right: 8px; }

.right {
  margin-left: 8px; }

.plan h3 {
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #fcccd8;
  padding: 4px 0; }

.plan h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: #fde5eb; }

.selected {
  background: #fff3c2;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1);
  color: #f0003f; }

.selected h3 {
  color: #615c51; }

.selected h1 {
  color: #423d33; }
