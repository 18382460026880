.content {
  margin-top: 16px; }

.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  grid-template-areas: '. e e e e e e e e e e .'; }

.e {
  grid-area: e; }

.loading {
  display: flex;
  flex-flow: column;
  height: 100vh; }

@media (min-width: 640px) {
  .content {
    margin-top: 64px; } }
