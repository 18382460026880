.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  grid-template-areas: '. nav nav nav nav nav nav nav nav nav nav .';
  padding-top: 12px; }

.container.fill {
  background-color: #f0003f; }

.container.thick {
  padding-top: 24px; }

.blank {
  height: 75px; }

.nav {
  position: relative;
  grid-area: nav;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }

.logo {
  padding-top: 16px;
  padding-bottom: 6px; }

.center {
  justify-content: center; }

.right {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 6px;
  display: flex;
  flex-direction: row;
  align-items: center; }

.textLinks {
  bottom: 8px; }

.linkbox {
  position: relative;
  display: inline-block;
  width: 48px;
  text-decoration: none;
  outline: none; }

.hoverbar {
  position: absolute;
  bottom: -13px;
  background-color: none;
  height: 3px;
  width: 100%; }

.tb {
  font-family: 'Lato', sans-serif;
  text-transform: lowercase;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  padding-top: 8px;
  color: #4f4940; }

.linkbox:hover .tb {
  color: #f0003f; }

.fill .tb {
  color: white; }

.fill .linkbox:hover .tb {
  color: white; }

.fill .linkbox:hover .hoverbar {
  background-color: white; }

.menu {
  font-family: 'Lato', sans-serif; }

.menu > button > span {
  background-color: #f7c948; }

.navbutton {
  margin-right: 16px; }

.selected {
  display: flex;
  flex-direction: row;
  padding: 0.75rem; }

.avatar {
  background-color: #f7c948;
  font-family: 'Roboto', sans-serif; }

.selected .info {
  margin-left: 0.5rem;
  line-height: 1.1;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.info h1 {
  font-size: 1rem;
  font-weight: 700;
  font-family: 'Roboto', sans-serif; }

.info h2 {
  font-size: 0.75rem; }

.itemHeader span {
  text-transform: lowercase;
  font-family: 'Roboto', sans-serif; }

.linknostyle,
.linknostyle:visited {
  text-decoration: none;
  outline: none;
  color: inherit; }

.linknostyle button:hover,
.linknostyle button:focus,
.linknostyle button:active {
  border-color: #f7c948;
  color: #f7c948; }

@media (min-width: 640px) {
  .linkbox {
    width: 64px; }
  .logo {
    padding-top: 24px;
    padding-bottom: 12px; }
  .right {
    padding-bottom: 12px; }
  .textLinks {
    bottom: 14px; }
  .tb {
    font-size: 14px;
    padding-top: 15px; }
  .hoverbar {
    bottom: -22px; } }

@media (min-width: 1200px) {
  .nav {
    justify-content: center; } }
